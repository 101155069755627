import { AppBar, Box, Button, Card, CardActions, CardContent, Divider, IconButton, Menu, MenuItem, Popover, styled, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import SwitchAccountOutlined from '@mui/icons-material/SwitchAccountOutlined'
import { useLocation } from 'react-router-dom'
import { getAuthTokenFromCookie } from '../../Services/CookieAccessService'
import { pathParts, paths } from '../../Models/DataModels/Common/RedirectionModel'
import { UserInfo } from '../../Models/DataModels/Common/UserInfoModel'
import { LogoutReason, LogoutReasonType } from '../../Models/DataModels/Requests/AuthRequests'
import { switchToAdminAccount } from '../../Services/AuthenticationService'
import { LoginResponse } from '../../Models/DataModels/Responses/AuthResponses'
import { EmptyStatusCode, NotOKResponseModel } from '../../Models/DataModels/Common/NotOKResponseModel'
import { ErrorHandler, ErrorHandlerProps } from '../Common/Utility/ErrorHandler'
import AlertModal, { AlertButtonType, AlertModalProps } from '../Common/Modals/AlertModal'
import SubscriptionModal, {SubscriptionModalProps} from '../Common/Modals/SubscriptionModal'
import { errorRedColor } from '../Common/GlobalSettings/GlobalStyles'

const PageIndex = {
    searchHome: 0,
    autoTrac: 1,
    financialTools: 2,
    admin: 3,
    help: 4
}

const PageNames = {
    searchHome: 'Search',
    autoTrac: 'AutoTrac',
    financialTools: 'Financial-Tools',
    admin: 'Admin',
    help: 'Help'
}

interface CardActionInfo {
    text: string,
    toLink: string,
    clickAction: () => void
}

export interface HeaderProps {
    userInfo?: UserInfo | null,
    checkIsManager: () => boolean,
    checkIsAnonymous: () => boolean,
    checkIsTrial: () => boolean,
    login: (loginResponse: LoginResponse) => void,
    signOut: (logoutReason: LogoutReasonType) => void,
    setShowSaved: Dispatch<SetStateAction<boolean>>
}

const Header = ({
    userInfo,
    checkIsManager,
    checkIsAnonymous,
    checkIsTrial,
    login,
    signOut,
    setShowSaved
}: HeaderProps) => {
    const [selectedPageIndex, setSelectedPageIndex] = useState<number>()
    const [errorResponse, setErrorResponse] = useState<NotOKResponseModel | null>()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [password, setPassword] = useState<string>('')
    const [errorInputPassword, setErrorInputPassword] = useState<boolean>(false)
    const [helpTextPassword, setHelpTextPassword] = useState<string>('')
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

    const resetErrorStates = () => {
        setErrorInputPassword(false)
        setHelpTextPassword('')
        setErrorResponse(null)
    }

    const setPasswordError = (error: string) => {
        setHelpTextPassword(error)
        setErrorInputPassword(true)
    }

    const validatePassword = () => {
        if (!password) {
            setPasswordError('Password is required.')
            return false
        }
        return true
    }

    const isInputsValid = () => {
        resetErrorStates()
        let isValid: boolean = validatePassword()
        return isValid
    }

    const clearSelectedPageIndex = () => {
        setSelectedPageIndex(undefined)
    }

    const pageList = {
        [PageIndex.searchHome]: {
            name: PageNames.searchHome,
            link: pathParts.search.searchDefault
        },
        [PageIndex.autoTrac]: {
            name: PageNames.autoTrac,
            link: paths.autoTrac
        },
        [PageIndex.financialTools]: {
            name: PageNames.financialTools,
            link: paths.financialTools.base
        },
        [PageIndex.admin]: {
            name: PageNames.admin,
            link: paths.admin.base
        },
        [PageIndex.help]: {
            name: PageNames.help,
            link: paths.help.base
        }
    }

    const pages = [
        <Link className='nav-link' to={pathParts.search.searchDefault}>Search</Link>,
        ...(checkIsTrial() || checkIsAnonymous()) ?
            []
            :
            [
                <Link className='nav-link' to={paths.autoTrac}>AutoTrac</Link>,
                <Link className='nav-link' to={paths.financialTools.base}>Financial-Tools</Link>
            ],
        ...checkIsManager() ?
            [
                <Link className='nav-link' to={paths.admin.base}>Admin</Link>
            ]
            :
            [],
        <Link className='nav-link' to={paths.help.base}>Help</Link>
    ]

    const accountOptions: CardActionInfo[] = [
        {
            text: 'View Account',
            toLink: paths.account.base,
            clickAction: clearSelectedPageIndex
        },
        {
            text: 'Log Off',
            toLink: paths.login,
            clickAction: () => signOut(LogoutReason.Logout)
        }
    ]

    const upgradeModalProps: SubscriptionModalProps = {
        showModal: showUpgradeModal,
        setShow: setShowUpgradeModal,
        checkIsTrial: checkIsTrial
    }
    
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (selectedPageIndex === undefined
            || pageList[selectedPageIndex]?.link !== location?.pathname) {
            switch (location?.pathname) {
                case pathParts.search.searchDefault:
                    setSelectedPageIndex(PageIndex.searchHome)
                    break
                case paths.autoTrac:
                    setSelectedPageIndex(PageIndex.autoTrac)
                    break
                case paths.admin.base:
                    setSelectedPageIndex(PageIndex.admin)
                    break
                case paths.financialTools.base:
                    setSelectedPageIndex(PageIndex.financialTools)
                    break
                case paths.help.base:
                    setSelectedPageIndex(PageIndex.help)
                    break
                default:
                    setSelectedPageIndex(undefined)
                    break
            }
        }
    }, [location?.pathname])

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const onSelectPageButton = (pageIndex: number | undefined, page: any) => (e: any) => {
        setSelectedPageIndex(pageIndex)

        if (page.link.includes('selectedResults')) {
            setShowSaved(true)
        } else {
            setShowSaved(false)
        }
        handleCloseNavMenu()
    }

    if ((location.pathname === (paths.login)) || (location.pathname === (paths.login + '/'))) {
        return <></>
    }

    const renderName = () => {
        if (userInfo?.firstName?.trim() || userInfo?.lastName?.trim()) {
            return (<Typography gutterBottom variant="h5" component="div">
                {`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
            </Typography>)
        }

        return <></>
    }

    const renderSubscriptionInfo = () => {
        if (true) {
            return (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 2}}>
                    <b>Subscription type:</b>
                    <br/>
                    { checkIsTrial() ? 'Trial' : 'Paid' }
                    <br/>
                    <a onClick={() => {
                        handleCloseUserMenu();
                        setShowUpgradeModal(true);
                    }} className='upgradeLink'>Upgrade subscription</a>
                </Typography>)
        }

        return<></>
    }

    const renderEmail = () => {
        if (userInfo?.email?.trim()) {
            return (<Typography variant="body2" color="text.secondary">
                {`${userInfo?.email}`}
            </Typography>)
        }

        return <></>
    }

    const renderOrganization = () => {
        if (userInfo?.orgName?.trim()) {
            return (<Typography variant="body2" color="text.secondary">
                <b>{`${userInfo?.orgName}`}</b>
            </Typography>)
        }

        return <></>
    }

    const renderPasswordPrompt = () => {
        return <>
            {`Enter the password to switch to your admin account [${userInfo?.impersonator?.username}]:`}
            <TextField id="passwordField" label="Password" variant="outlined"
                type={'password'}
                sx={{ paddingBottom: 1, width: '80%', marginTop: '25px' }}
                InputLabelProps={{
                    shrink: true,
                    style: { fontWeight: 'bold' }
                }}
                size='small'
                value={password}
                onChange={(e) => {
                    setPassword(e.target.value)
                    resetErrorStates()
                }}
                error={errorInputPassword}
                helperText={helpTextPassword}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        doSwitchToAdminUser()
                    }
                }}
            />
        </>
    }

    const alertModalProps: AlertModalProps = {
        showModal,
        setShowModal,
        AlertTitle: 'Confirm',
        AlertContent: renderPasswordPrompt(),
        AlertButtons: [
            {
                type: AlertButtonType.OK,
                display: 'Submit',
                onClick: () => {
                    doSwitchToAdminUser()
                },
                isPrimary: true
            },
            {
                type: AlertButtonType.Cancel,
                display: 'Cancel',
                onClick: () => {
                    setPassword('')
                    setShowModal(false)
                },
                isPrimary: false
            }
        ],
        onAlertClose: () => {
            setPassword('')
            return true
        }
    }

    const doSwitchToAdminUser = () => {
        if (!isInputsValid()) {
            return
        }

        switchToAdminAccount({ password: password })
            .then((response: LoginResponse) => {
                setPassword('')
                login(response)
                window.location.reload()
                setShowModal(false)
                navigate(pathParts.search.searchDefault)
            },
                (notOKResponseModel: NotOKResponseModel) => {
                    setErrorResponse(notOKResponseModel)
                    if (notOKResponseModel?.statusCode !== EmptyStatusCode) {
                        setErrorInputPassword(true)
                    }
                })
    }

    const requestSwitchToAdminUser = () => {
        handleCloseUserMenu()
        setShowModal(true)
    }

    const renderImpersonator = () => {
        if (userInfo?.impersonator) {
            return <>
                <Divider variant="middle" style={{ marginTop: '8px', marginBottom: '8px' }}>Impersonator</Divider>
                <Typography variant="body2" color="text.secondary">
                    {`${userInfo?.impersonator?.name}`}
                    <br />
                    {`${userInfo?.impersonator?.username}`}
                </Typography>
                <Box textAlign='center' style={{ marginTop: '8px' }}>
                    <Button id='switchToAdmin' variant="outlined" onClick={requestSwitchToAdminUser}>Switch to Admin</Button>
                </Box>
                <Divider variant="middle" style={{ marginTop: '16px' }}></Divider>
            </>
        }
    }

    const renderAccountCard = () => {
        return (<Popover sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    {renderName()}
                    {renderOrganization()}
                    {renderEmail()}
                    {renderImpersonator()}
                    {renderSubscriptionInfo()}
                </CardContent>
                <CardActions>
                    {accountOptions.map((cardActionOption: CardActionInfo, index: number) => (
                        <div key={`AccountOptions-${index}`} style={{ display: 'block', marginLeft: '3px' }}>
                            <Button key={`AccountOptions-${index}`} size='small' onClick={() => {
                                handleCloseUserMenu()
                                cardActionOption.clickAction()
                                navigate(cardActionOption.toLink)
                            }}><Typography textAlign='center'>{cardActionOption.text}</Typography></Button>
                        </div>
                    ))}
                </CardActions>
            </Card>
        </Popover>)
    }

    const errorHandlerProps: ErrorHandlerProps = {
        response: errorResponse,
        signOut: signOut
    }

    return (
        <>
            <AlertModal {...alertModalProps} />
            {showUpgradeModal && <SubscriptionModal {...upgradeModalProps} />}
            <ErrorHandler {...errorHandlerProps} />
            { checkIsTrial() && getAuthTokenFromCookie() ? (
                <Button variant='text' sx={{ width: '100%', padding: 0.5, backgroundColor: errorRedColor, color: 'white', textTransform: 'none', borderRadius: 0, '&:hover': {
                    backgroundColor: 'white',
                    color: errorRedColor
                } }}
                    onClick={() => setShowUpgradeModal(true)}
                >Click Here to Upgrade Subscription</Button>
            ) : '' }
            <AppBar position='static' sx={{ backgroundColor: '#707070', mb: 1 }}>
                <Toolbar disableGutters>
                    <Typography
                        variant='h6'
                        noWrap
                        component='a'
                        href='/'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={require('../../Images/GFDFinaeonLogo3.png')} style={{ width: '192px', height: '39px' }} alt='Finaeon Logo' />
                    </Typography>

                    {/* COLLAPSED VERSION */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <Tooltip title='Navigate the site'>
                            <IconButton
                                size='large'
                                aria-label='account of current user'
                                aria-controls='menu-appbar'
                                aria-haspopup='true'
                                onClick={handleOpenNavMenu}
                                color='inherit'
                            >
                                <MenuIcon />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page, index) => (
                                <MenuItem key={index} onClick={handleCloseNavMenu}>
                                    <Typography textAlign='center'>{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant='h5'
                        noWrap
                        component='a'
                        href=''
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        <img src={require('../../Images/GFDFinaeonLogo3.png')} style={{ width: '192px', height: '39px' }} alt='Finaeon Logo' />
                    </Typography>

                    {/* NORMAL NO COLLAPSED VERSION */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginLeft: '0 !important' }}>
                        {getAuthTokenFromCookie() && Object.values(pageList).map((page, index) => {
                            return (
                                <StyledLink
                                    className='nav-link'
                                    to={page.link}
                                    key={index}
                                    onClick={onSelectPageButton(index, page)}
                                    style={{
                                        ...(index === selectedPageIndex ? { backgroundColor: '#000000' } : {}),
                                        ...([PageNames.searchHome, PageNames.help].includes(page.name)
                                            || ([PageNames.autoTrac, PageNames.financialTools].includes(page.name) && !checkIsTrial() && !checkIsAnonymous())
                                            || (page.name === PageNames.admin && checkIsManager()) ? {} : { display: 'none' })
                                    }}
                                >
                                    {page.name}
                                </StyledLink>
                            )
                        })}
                        {!checkIsTrial() && userInfo?.orgName &&
                            <Typography variant='caption' sx={{ paddingLeft: 3, paddingTop: 3, color: '#FFFFFF', fontStyle: 'italic' }}>Access to site provided by {userInfo?.orgName}</Typography>
                        }
                    </Box>
                    {/* RIGHT SIDE ACCOUNT/LOG OUT MENU */}
                    {getAuthTokenFromCookie() ?
                        <Box sx={{ flexGrow: 0, p: 0 }}>
                            <Tooltip title='Open account/Log off'>
                                <IconButton
                                    size='large'
                                    aria-label='account of current user'
                                    aria-controls='menu-appbar'
                                    aria-haspopup='true'
                                    onClick={handleOpenUserMenu}
                                    color='inherit'
                                >
                                    {
                                        userInfo?.impersonator ? <SwitchAccountOutlined fontSize='large' /> : <AccountCircle fontSize='large' />
                                    }
                                </IconButton>
                            </Tooltip>
                            {renderAccountCard()}
                        </Box>
                        :
                        <Typography textAlign='center' paddingRight='16px' color='#FFFFFF'><Link className='nav-link' to={paths.login}>Log In</Link></Typography>
                    }
                </Toolbar>
            </AppBar>
        </>
    )
}


export const StyledLink = styled(Link)(({ theme }) => ({
    borderRadius: '5px',
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 6,
    color: '#FFFFFF',
    display: 'block',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    '&:hover': { backgroundColor: '#405E5E', color: '#FFFFFF' },
    textTransform: 'uppercase',
    fontFamily: theme?.typography?.fontFamily,
    fontSize: 14,
    fontStretch: '100%',
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: '0.39998px'
}))

export default Header
