import { Box } from '@mui/material'
import React from 'react'

export interface MaintenanceModeProps {
    maintenanceMode: boolean,
    maintenanceModeMessage?: string | null
}

const MaintenanceMode = ({
    maintenanceMode,
    maintenanceModeMessage
}: MaintenanceModeProps) => {

    if (!maintenanceMode) {
        return <></>
    }

    return (
        <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            minHeight='100vh'
        >
            <div>
                <div>
                    <img src={require('../../Images/Finaeon_logo_trademark_Transparent.png')} alt='Finaeon Logo' />
                </div>
                <div style={{ textAlign: 'center', fontWeight: 'bold', paddingTop: '20px' }}>
                    {maintenanceModeMessage}
                </div>
            </div>
        </Box>
    )
}

export default MaintenanceMode
